import { Flex, Tag, Tooltip } from "antd";
import { roundDays } from "../../hooks/slots";
import ResourceIcon from "../resources/ResourceIcon";

function ResourceMetric({ metrics, capacity }) {
  const { remaining_capacity, total_planned } = metrics || {};

  const percent = Math.round(100 * total_planned / (capacity || 0));

  let color = percent > 100 ? 'red' : (
    percent >= 80 ? 'orange' : 'green'
  )

  if (total_planned === undefined) {
    return null
  }
  return capacity > 0 ?
    <Tag color={color || 'default'}>
      <Flex align="center" gap={3}>
        <Tooltip title={<span>Total planifié et réalisé, pour l'année (fiscale) en cours<br /> <b>{roundDays(total_planned, 0.5)} jrs</b> / {capacity} jrs</span>}>
          {Math.round(percent)}%
        </Tooltip>
      </Flex>
    </Tag>
    :
    <Tooltip title="Total planifié et réalisé, pour l'année (fiscale) en cours" >
      <Tag color={total_planned > 0 ? 'blue' : 'default'}>{roundDays(total_planned || 0, 0.5)} jrs</Tag>
    </Tooltip>
}

export default function SlotResourceItemRenderer({ name, resource = {}, metrics }) {

  return (
    <div className="slot-cell">
      <Flex gap="small" align="center" wrap="nowrap" style={{ flex: 1, minWidth: 0, }}>
        <ResourceIcon type={resource.type} size={0.7} />
        <span className="slot-text-truncated" style={{ flex: 1 }}>
          {name}
        </span>

        <ResourceMetric metrics={metrics} capacity={resource.capacity} />
      </Flex>
    </div >
  );
}