// SupportChatbot.jsx
import Icon, { SendOutlined } from '@ant-design/icons';
import { mdiRobotConfusedOutline } from '@mdi/js';
import { Button, Card, Flex, Input, List, Modal, Spin, Typography, message } from 'antd';
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { askSupport } from '../../services/api/support';

const { Text } = Typography;

export default function SupportChatbot({ onClose }) {
    const [messageText, setMessageText] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [end, setEnd] = useState(false);
    const { givenName, role, permissions } = useAuth();

    const chatListRef = useRef(null);


    const takeScreenshot = async () => {
        try {
            const canvas = await html2canvas(document.body);
            return canvas.toDataURL('image/jpeg', 0.8);
        } catch (error) {
            console.error('Erreur lors de la capture:', error);
            message.error('Impossible de prendre une capture d\'écran');
            return null;
        }
    };
    useEffect(() => {
        (async () => {
            const screenshot = await takeScreenshot()

            setMetadata({
                screenshot,
            });
            setChatHistory([
                {
                    type: 'reply',
                    message: 'Bonjour! Il semblerait que vous ayez besoin d\'aide. Comment puis-je vous aider?',
                    attachments: [
                        {
                            type: 'image',
                            name: 'Capture',
                            data: screenshot,
                        }
                    ]
                }
            ]);
            setIsModalVisible(true);
        })();
    }, []);
    const sendMessage = async () => {
        if (!messageText.trim()) return;

        setIsLoading(true);

        // Ajouter le message de l'utilisateur à l'historique
        setChatHistory(prev => [...prev, { type: 'request', message: messageText }]);

        try {
            // Prendre une capture d'écran
            // const screenshot = await takeScreenshot();



            let context = "Caractéristiques :";
            context += "Navigateur: " + navigator.userAgent + "\n";
            context += "Résolution: " + window.screen.width + "x" + window.screen.height + "\n";
            context += "Langue: " + navigator.language + "\n";
            context += "Utilisateur: " + givenName + "\n";
            context += "Role: " + role + "\n";
            context += "Permissions: {" + Array.from(permissions) + "}\n";




            // Appeler l'API Lambda
            const response = await askSupport({
                context,
                messages: [...chatHistory, {
                    type: 'request',
                    message: messageText,
                }]
            });;

            if (response.end) {
                setEnd(true);
            }


            // Ajouter la réponse de Bedrock à l'historique
            setChatHistory(prev => [...prev, { type: 'reply', message: response.message }]);
        } catch (error) {
            console.error('Erreur:', error);
            setChatHistory(prev => [...prev, {
                type: 'system',
                message: 'Une erreur est survenue. Veuillez réessayer.'
            }]);
            message.error('Erreur de connexion au serveur');
        } finally {
            setIsLoading(false);
            setMessageText('');
        }
    };

    // Fonction pour faire défiler vers le bas
    const scrollToBottom = () => {
        if (chatListRef.current) {
            chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    return (<>

        <Modal

            open={isModalVisible}
            onCancel={() => { setIsModalVisible(false); onClose(); }}
            footer={null}
            title={<Flex align="center" gap="small">
                <Icon path={mdiRobotConfusedOutline} size={1} />
                <span>Support (Beta)</span>
            </Flex>}
        >
            <Flex vertical >

                <List
                    className="chat-history"
                    style={{
                        overflowY: 'auto',
                        maxHeight: "500px",
                        padding: 10,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 10
                    }}
                    itemLayout="horizontal"
                    ref={chatListRef}
                    dataSource={chatHistory}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                padding: '8px 0'
                            }}
                        >
                            <Flex justify={item.type === 'request' ? 'end' : 'start'} style={{ width: '100%' }}>
                                <Card
                                    style={{
                                        display: 'inline-block',
                                        maxWidth: '80%',
                                        backgroundColor: item.type === 'request' ? '#1890ff' : (item.type === 'system' ? '#ff4d4f' : '#ffffff'),
                                        color: item.type === 'request' ? 'white' : 'inherit'
                                    }}
                                    size="small"
                                >
                                    <Text
                                        style={{
                                            color: item.type === 'request' ? 'white' : (item.type === 'system' ? 'white' : 'inherit')
                                        }}
                                    >
                                        {item.message}
                                    </Text>

                                    {/* {item.attachments && item.attachments.map((attachment, index) => (
                                    <div key={index}>
                                        {attachment.type === 'image' && (
                                            <img src={attachment.data} alt="Attachment" style={{ maxWidth: '100px', borderRadius: 10, marginTop: 8 }} />
                                        )}
                                    </div>
                                ))} */}
                                </Card>
                            </Flex>

                        </List.Item>
                    )}
                />

                {isLoading && (
                    <div style={{ textAlign: 'center', margin: '10px 0' }}>
                        <Spin tip="En cours..." />
                    </div>
                )}

                {!end && <div style={{ display: 'flex', marginTop: 16 }}>
                    <Input
                        placeholder={'Tapez votre message...'}
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        onPressEnter={sendMessage}
                        disabled={isLoading}
                        style={{ marginRight: 8 }}
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={sendMessage}
                        disabled={isLoading}
                    >
                        Envoyer
                    </Button>
                </div>}

                {end && <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    <Button type="primary" onClick={() => setIsModalVisible(false)}>Fermer</Button>
                </div>}
            </Flex>
        </Modal >
    </>
    );
};

