import { Button, DatePicker, Flex, Select, Skeleton, Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { orderStatuses, orderTypes, useOrders } from '../../hooks/orders';
import { usePickingPoolAllOrders } from '../../hooks/pickingpools';
import { formatDate } from '../../lib/format';
import { indexBy } from '../../lib/utils';

import { ReloadOutlined } from '@ant-design/icons';
import { mdiTrayFull, mdiWarehouse } from '@mdi/js';
import Link from '../../components/link/Link';
import OrderStatus from '../../components/orders/OrderStatus';
import Search from '../../components/search/Search';
import SearchButton from '../../components/search/SearchButton';
import { useAgencies } from '../../hooks/agencies';
import { useAuth } from '../../hooks/auth';
import { useProjectsLoader } from '../../hooks/projects';
import { useSalesmen } from '../../hooks/resources';
import { useSearch, useSearchOptionsListMemo, useSearchOptionsMemo } from '../../hooks/search';
import { usePersistentState } from '../../hooks/state';
import { useWarehouses } from '../../hooks/warehouses';
import SalesmanLabel from '../resources/SalesmanLabel';
import WarehouseLabel from '../warehouses/WarehouseLabel';



const orderTypesIdx = indexBy(orderTypes, 'value');



function ProjectRenderer({ project }) {
  return <Link to={"/projects/" + project.id}>{project.name}</Link>;
}



export default function OrdersTable({
  onAddToPickingPool,
  inPoolOrderIds,
  warehouseId,
  showPools = false,

  extraActionsButtons = [],

  onSelectionChange = null,
  selectedOrdersIds = [],

  filterFn = null,


  initialSource = 'orders',
}) {
  const { can } = useAuth();



  const [ordersSource, setOrdersSource] = usePersistentState("OrderList.ordersSource", initialSource); // 'orders' | 'pools
  const [dateRange, setDateRange] = usePersistentState('OrderList.dateRange', [
    new dayjs(), new dayjs().add(15, 'days')
  ]);
  const [orders, refreshOrders, complete] = useOrders(dayjs(dateRange[0]).toDate(), dayjs(dateRange[1]).toDate());
  const pickingPoolAllOrders = usePickingPoolAllOrders(warehouseId);
  const { projects, getProject } = useProjectsLoader();

  const [agencies] = useAgencies();
  const [warehouses] = useWarehouses();


  const agenciesById = useMemo(() => indexBy(agencies, 'id'), [agencies]);


  const searchPoolOptions = useSearchOptionsListMemo({
    name: 'pools',
    options: pickingPoolAllOrders,
    fn: useCallback((p) => ({
      value: p.pool_id,
      label: p.pool_name
    }), []),
    color: 'blue',
    icon: mdiTrayFull,

  });


  const statusesOptions = useSearchOptionsListMemo({
    title: 'Status',
    name: 'statuses',
    options: orderStatuses,
    fn: useCallback((o) => ({
      value: 'status-' + o.value,
      label: o.label,
    }), []),
    optionColor: '#7cb305',
    tagColor: 'lime',
    // icon: mdiStoreOutline,
  });

  const typesOptions = useSearchOptionsListMemo({
    title: 'Type',
    name: 'types',
    options: orderTypes,
    fn: useCallback((o) => ({
      value: 'type-' + o.value,
      label: o.label
    }), []),
    optionColor: '#d48806',
    color: 'gold',
    // icon: mdiStoreOutline,
  });

  const searchWarehouseOptions = useSearchOptionsListMemo({
    name: 'warehouses',
    options: warehouses,
    fn: useCallback((w) => ({
      value: w.id,
      label: 'Dépôt / ' + w.name
    }), []),
    color: 'grey',
    icon: mdiWarehouse,
  });

  const [salesmen] = useSalesmen();

  const thirdparties = useSearchOptionsMemo(orders, (o) => {
    return {
      value: o.third_party_id || o.customer_code,
      name: o.third_party_name || o.customer_name || `Client ${o.customer_code}`,
    }
  });


  const items = useMemo(() => {
    switch (ordersSource) {
      case 'orders':
        return orders.filter(o => o.nature !== 'CTT');
      case 'pools':
        return pickingPoolAllOrders;
      case 'transfers':
        return orders.filter(o => o.nature === 'CTT');
      default:
        return [];
    }
  }, [orders, pickingPoolAllOrders, ordersSource]);



  const { list: filteredOrders, filters, setFilters, addFilter: addOrderFilter, options, setCustomFilters, customFilters } = useSearch({
    name: 'OrderList',
    items: useMemo(() => (items).map(o => ({
      ...o,
      status: o.status || 'pending',
      type: o.nature === 'CTT' ? 'transfer' : o.items_count && o.prestations_count ? 'mixed' : o.items_count ? 'sales' : 'prestations',
    })
    ), [items]),
    filterFn: useCallback((o, { check, statuses, orders, projects, thirdparties, agencies, pools, resources, warehouses, projectTypes, projectInvoicingModes }, fulltextSearch) => {
      if (o.nature === 'CTT') {
      }
      if (filterFn && !filterFn(o)) return false;
      if (pools && pools.length && !pools.includes(o.pool_id)) return false;

      if (!check('pools', o.pool_id)) return false;
      if (!check('orders', o.id)) return false;
      if (!check('projects', o.project_id)) return false;
      if (!check('thirdparties', o.customer_code)) return false;
      if (!check('agencies', o.agency_id)) return false;
      if (!check('salesmen', 'salesman-' + o.salesman_code)) return false;
      if (!check('types', 'type-' + o.type)) return false;

      if (!check('statuses', 'status-' + o.status)) return false;

      const proj = getProject(o.project_id)
      if (!check('projectTypes', 'projectType-' + proj?.project_type)) return false
      if (!check('projectInvoicingModes', 'projectInvoicingMode-' + proj?.project_invoicing_mode)) return false

      // if (!check('warehouses', Object.keys(o.remaining_items_count_by_warehouse))) return false;


      // if (orders && orders.length && !orders.includes(o.id)) return false;
      // if (projects && projects.length && !projects.includes(o.project_id)) return false;
      // // if (projectTypes && projectTypes.length && !projectTypes.includes(project.project_type)) return false;
      // // if (projectInvoicingModes && projectInvoicingModes.length && !projectInvoicingModes.includes(projectInvoiceModeByProjectID[project.id])) return false;
      // if (thirdparties && thirdparties.length && !thirdparties.includes(o.customer_code)) return false;
      // if (agencies && agencies.length && !agencies.includes(o.agency_id)) return false;
      // if (resources && resources.length && !resources.includes(o.salesman_code)) return false;


      if (warehouses && warehouses.length && !warehouses.some(warehouse => {
        const code = warehouse.slice(3) // trim 'wh-' prefix
        return o.remaining_items_count_by_warehouse[code] > 0
      })) return false;
      return fulltextSearch(`${o.order_number} ${o.ref_internal} ${getProject(o.project_id)?.name} ${o.salesman_code}`)
    }, [getProject, filterFn]),

    salesmen,
    projects: projects,
    agencies: agencies,
    thirdparties: thirdparties,
    orders: ordersSource === 'pools' ? pickingPoolAllOrders : orders,

    enableProjectTypes: true,
    enableProjectInvoicingModes: true,

    options: [
      ...searchPoolOptions,
      ...warehouseId ? [] : searchWarehouseOptions,
      ...statusesOptions,
      ...typesOptions,
    ]

  });



  const displayedOrders = filteredOrders




  const disabledDate = (current, { from } = {}) => {
    if (from) {
      return Math.abs(current.diff(from, 'month')) > 3;
    }
    return false;
  };

  useEffect(() => {
    if (!showPools && ordersSource !== 'orders') {
      setOrdersSource('orders'); // Force orders source
    }
  }, [showPools, ordersSource, setOrdersSource]);


  // const [pickingPoolOrders, setPickingPoolsOrders] = useState([])



  const lockedOrdersIdx = useMemo(() => {
    const out = {}
    for (const orderID of inPoolOrderIds) {
      out[orderID] = true;
    }
    return out;
  }, [inPoolOrderIds]);


  const warehousesById = useMemo(() => {
    return indexBy(warehouses, 'id')
  }, [warehouses])

  const isFromPools = ordersSource === 'pools';


  const columns = useMemo(() => {

    const columns = [];

    if (ordersSource === 'pools') {
      columns.push({
        title: 'Pool',
        dataIndex: 'pool_id',
        key: 'pool_id',
        render: (pool_id, item) => <SearchButton
          type="pools"
          value={pool_id}
          handler={addOrderFilter}>
          {item.pool_name}
        </SearchButton>,
      }, {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        defaultSortOrder: "ascend",
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.position - b.position,
        render: (position) => position + 1,
      })
    }

    columns.push(
      {
        title: 'Numéro',
        dataIndex: 'order_number',
        key: 'order_number',
        width: 100,
        render: (order_number, o) => <Link to={`/orders/${o.id}`}>{order_number}</Link>
      });

    if (ordersSource === 'orders' || ordersSource === 'pools') {
      columns.push({
        title: 'Type de commande',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (type) => <SearchButton
          type="types"
          value={'type-' + type}
          handler={addOrderFilter}>
          <Tag color={orderTypesIdx[type]?.color}>{orderTypesIdx[type]?.label}</Tag>
        </SearchButton>,
      })
    }
    columns.push(
      {
        title: 'Ref / Affaire / Client',
        dataIndex: 'project_id',
        key: 'project_id',
        render: (project_id, s) => {
          const project = getProject(project_id);

          return <Flex vertical>
            <strong>{s.ref_internal}</strong>
            {project &&
              <SearchButton type="projects" value={project_id} handler={addOrderFilter}>
                <ProjectRenderer project={project} />
              </SearchButton>}
            <SearchButton type="thirdparties" value={s.third_party_code} handler={addOrderFilter} >
              {s.customer_code} - {s.third_party_name}
            </SearchButton>
          </Flex>
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 100,
        render: (date) => formatDate(date),
      }
    );

    if (ordersSource === 'orders' || ordersSource === 'pools') {
      columns.push(
        {
          title: 'Agence',
          dataIndex: 'agency_id',
          key: 'agency_id',
          width: 150,
          render: (agency_id) => {
            const agency = agenciesById[agency_id];
            return <SearchButton
              type="agencies"
              value={agency_id}
              handler={addOrderFilter}>
              <Link to={`/agencies/${agency_id}`}>{agency?.name}</Link>
            </SearchButton>
          }
        },
        {
          title: 'Commercial',
          dataIndex: 'salesman_code',
          key: 'salesman_code',
          width: 100,
          render: (salesman_code) => <SearchButton
            type="text"
            value={salesman_code}
            handler={addOrderFilter}>
            <SalesmanLabel code={salesman_code} showCode />
          </SearchButton>,
        })
    }
    if (ordersSource === 'transfers') {
      columns.push({
        title: 'Départ',
        dataIndex: 'warehouse_id',
        key: 'warehouse_id',
        render: (whId) => {
          return <SearchButton
            type="warehouses"
            value={whId}
            handler={addOrderFilter}>
            <WarehouseLabel id={whId} />
          </SearchButton>
        }
      },
        {
          title: 'Destination',
          dataIndex: 'warehouse_dest_id',
          key: 'warehouse_dest_id',
          render: (whId) => {
            return <SearchButton
              type="warehouses"
              value={whId}
              handler={addOrderFilter}>
              <WarehouseLabel id={whId} />
            </SearchButton>
          }
        }

      )
    }

    columns.push(
      {
        title: 'Date de livraison',
        dataIndex: 'shipping_date',
        key: 'shipping_date',
        width: 100,
        render: (date) => formatDate(date),
        ...(isFromPools ? {} : {
          defaultSortOrder: "ascend",
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => new Date(a.shipping_date) - new Date(b.shipping_date),
        })

      },

      {
        title: 'Statut',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (status) => <SearchButton
          type="statuses"
          value={'status-' + status}
          handler={addOrderFilter}>
          <OrderStatus value={status} />
        </SearchButton>,
      },

      {
        title: 'Nb réfs restantes',
        key: 'remaining_items_count',
        dataIndex: 'remaining_items_count',
        width: 80,
        sorter: (a, b) => a.remaining_items_count - b.remaining_items_count,
        render: (quantity, { remaining_items_count_by_warehouse }) => <span>
          {Object.entries(remaining_items_count_by_warehouse).map(([warehouseCode, count]) => {
            const name = warehousesById['wh-' + warehouseCode]?.name || warehouseCode;
            return <Tag key={warehouseCode}>{name}: <b>{count}</b></Tag>
            // return <Tag key={warehouseCode}>{warehouseCode}: {count}</Tag>
          })}
        </span>,
      },

      {
        title: '',
        key: 'actions',
        width: 100,
        render: (order) => <Space>
          {can('pickingpool.orders.update') && onAddToPickingPool && order.remaining_items_count > 0 && <Button type="primary" disabled={lockedOrdersIdx?.[order.id]} onClick={() => onAddToPickingPool(order.id, order.pool_id)}>À préparer</Button>}
        </Space>
      })


    return columns;

  }, [addOrderFilter, agenciesById, can, getProject, ordersSource, lockedOrdersIdx, onAddToPickingPool, warehousesById])


  return <Flex vertical gap="middle">
    <Flex vertical gap="small" className="box-paddingless">
      <Flex gap={"small"} style={{ flex: 1, padding: 10 }}>

        <Space.Compact style={{ flex: 1 }}>
          {showPools && (
            <Select
              style={{ minWidth: 130 }}
              options={[
                { label: 'Commandes', value: 'orders' },
                { label: 'Transferts', value: 'transfers' },
                { label: 'Pools', value: 'pools' },
              ]}
              onChange={(v) => setOrdersSource(v)}
              value={ordersSource}
            />)}

          <Search
            name='OrdersList'
            options={options}
            setCustomFilters={setCustomFilters}
            customFilters={customFilters}
            values={filters} onFiltersChange={setFilters} />
          <Button onClick={() => refreshOrders()} loading={!complete} icon={<ReloadOutlined />} />
        </Space.Compact>

        {ordersSource !== 'pools' &&
          <DatePicker.RangePicker
            title="Date de livraison"
            allowClear={false}
            style={{ minWidth: '250px' }}
            format="DD/MM/YYYY"
            value={[
              dayjs(dateRange[0]), dayjs(dateRange[1])]} disabledDate={disabledDate} onChange={setDateRange} />
        }

        {extraActionsButtons}

      </Flex>

      {complete


        ? <Table
          key={ordersSource}
          // bordered
          dataSource={displayedOrders}
          rowKey="id"
          size='middle'
          rowSelection={onSelectionChange ? {
            type: 'checkbox',
            // getCheckboxProps: (record) => ({
            //   disabled: !!record.ref,
            // }),
            selectedRowKeys: selectedOrdersIds,
            onChange: (selectedRowKeys) => {
              onSelectionChange(selectedRowKeys)
              // console.log(`selectedRowKeys: ${selectedRowKeys}`);
            }
          } : undefined}
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          columns={columns}
        />
        : <Skeleton active />}
    </Flex>


  </Flex >
}