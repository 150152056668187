import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { mdiCheckCircle, mdiCheckCircleOutline, mdiMagnifyPlusOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Badge, Button, Divider, Flex, Popover, Progress, Space, Tag, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useOrder } from "../../hooks/orders";
import { durationInDaysFromHours, slotStatusesIndex, useSlots } from "../../hooks/slots";
import { taskStatusesIdx } from "../../hooks/tasks";
import { formatDate, formatDateTime, formatNumber, formatTime } from "../../lib/format";
import { getTask, updateTaskStatus } from "../../services/api/tasks";
import { loaderWrap } from "../../services/loader";
import PrestationIcon from "../prestations/PrestationIcon";
import RefLabel from "../ref/RefLabel";
import ResourceLabel from "../resources/ResourceLabel";
import SalesmanLabel from "../resources/SalesmanLabel";
import TaskMessagesView from "../tasks/TaskMessagesView";
import TaskStatusBadge from "../tasks/TaskStatusBadge";
import TaskStatusLabel from "../tasks/TaskStatusLabel";
import TaskTypeLabel from "../tasks/TaskTypeLabel";
import TaskUnitLabel from "../tasks/TaskUnitLabel";


function DateButton({ date, color, onSelectDate }) {
  return <Link type="text"
    onClick={() => onSelectDate(date)} >
    <b style={{ color }}>{formatDate(date)}</b>
  </Link>
}


function CompletionIndicator({ percent, completeColor = "#52c41a", overflowMessage }) {

  return percent === 100
    ? <CheckCircleTwoTone twoToneColor={completeColor} />
    : (percent > 120
      ?
      <Tooltip title={overflowMessage || (Math.round(percent) + "%")} >
        <WarningTwoTone twoToneColor="orange" />
      </Tooltip>
      : <Progress type="circle"
        percent={percent}
        size={18} />
    )
};




function CompletionLabel({ percent, remaining = 0, unit = 'd' }) {
  // if (unit === 'j' && Math.abs(remaining) < 1) {
  //   remaining = Math.ceil(remaining / 7);
  //   unit = 'h';
  // } else if (unit === 'h' && Math.abs(remaining) > 7) {
  //   remaining = Math.ceil(remaining / 7);
  //   unit = 'd';
  // }
  if (unit === 'h') {
    unit = ' H';
  }
  if (unit === 'd') {
    unit = ' J';
  }
  return <Space align="middle">
    {remaining !== 0 ? <Badge
      count={`${remaining > 0 ? '+' : ''}${formatNumber(remaining)} ${unit}`}
      color={remaining < 0 ? '#faad14' : undefined} /> : null}
    <CompletionIndicator percent={percent} completeColor={taskStatusesIdx.planned.color} />
  </Space>

}

function Prop({ label, value, children }) {
  return <Flex gap="small" >
    <div>{label} : </div>
    {children || <b>{value}</b>}
  </Flex>
}

function PopoverContent({ task, prestation, onSelectDate, onClickNewSlot, addSearchFilter, onChange }) {
  const navigate = useNavigate();
  const [slots, loadSlots] = useSlots();
  const { order } = useOrder(task?.order_id);
  const { can } = useAuth();
  const ownSlots = useMemo(() => (slots || []).filter(s => s.task_id === task?.id), [slots, task?.id]);

  const [previousTasks, setPreviousTasks] = useState([]);

  useEffect(() => {
    if (task) {
      loadSlots({ task_id: task.id });
      if (task.previous_task_ids) {
        Promise.all(task.previous_task_ids.map(id => getTask(id).catch(() => null)))
          .then(tasks => {
            setPreviousTasks(tasks.filter(t => t).map(t => t.item))
          })
      }
    }
  }, [task, loadSlots]);

  let unit = task?.unit
  // if (task?.unit === 'h') {
  //   unit = 'h';
  // }
  const isDuration = unit === 'h' || unit === 'd';
  const duration = task?.duration;
  const { cost, duration: duration_hours, planned_cost, planned_duration, planned_duration_percent, planned_cost_percent } = task.metrics || {};

  const onStatusChanged = useCallback((status) => {
    (async () => {
      await loaderWrap(updateTaskStatus(task.id, status));
      onChange && onChange();
    })();
  }, [task.id]);

  const formatTaskDuration = (d) => {
    if (unit === 'd') {
      return <span title={d + 'h'}>{durationInDaysFromHours(d) || '0'}j</span>;
    }
    return (d || '0') + 'h';
  }


  return <div style={{ maxWidth: 400 }}>
    <Flex>
      <TaskTypeLabel value={task.type} />
      <TaskStatusLabel value={task.status}
        deleted={task.deleted}
        editable={can('task.update')}
        onChange={onStatusChanged}
        allowedStatuses={['unplanned', 'inplanning', 'planned', 'complete']} />
    </Flex>
    <div>
      {prestation?.name} ({prestation?.code})
    </div>
    {task.description && <p style={{ color: '#052d4b', maxHeight: 150, overflow: 'auto', fontWeight: 300 }}>
      {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
    </p>}

    {task.order_id && <Prop label="Commande">
      <Flex gap="small" align="center" style={{ flex: 1 }}>
        <div>
          <Link to={`/orders/${task.order_id}`}>{task.order_number}{task.order_item_number ? <> / {task.order_item_number}</> : null}</Link>
        </div>
        {order && <div style={{ width: 50 }}>
          <Tooltip title={<>Marchandises traitées : <b>{order.items_count - order.remaining_items_count} / {order.items_count}</b></>} >
            <Progress
              size="small"
              percent={Math.round(100 * (order.items_count - order.remaining_items_count) / order.items_count)} />
          </Tooltip>
        </div>}
      </Flex>
    </Prop>}
    {task.salesman_code && <Prop label="Commercial" value={<SalesmanLabel code={task.salesman_code} clickable={false} showCode />} />}
    {task.ref && <Prop label="Référence" value={<RefLabel value={task.ref} />} />}

    {previousTasks?.length ? <div>
      <b><u>Tâches précédentes :</u></b>
      {previousTasks.map(t => <SlotTaskItemRenderer
        key={t.id}
        task={t}
        name={t.name} />)}
    </div> : null}

    {task.min_date || task.max_date ?
      <div>
        Dates : <DateButton date={task.min_date} color="green"
          onSelectDate={() => onSelectDate(task.min_date)} /> → <DateButton
          date={task.min_date} color="red"
          onSelectDate={() => onSelectDate(task.max_date)} />
      </div>
      : null}

    {cost > 0 || duration > 0 ?
      <div>
        Vendu :&nbsp;
        <b>{isDuration
          ? <>{duration} <TaskUnitLabel value={unit} /></>
          : <><TaskUnitLabel value={unit} /></>}</b>

        {duration > 0 && cost > 0 && (<span> / </span>)}
        {cost > 0 && (<b>{formatNumber(cost)} €</b>)}
        {/* <b>{expectedDuration} {unit}</b> / <b>{formatNumber(expectedAmount)} €</b> */}
      </div>
      : null}
    {isDuration ?
      <Flex align="center" gap="small">
        <div>Planifié : <b>{formatTaskDuration(planned_duration || 0)}</b>
          {/* &mdash; <b>{formatNumber(cost)} €</b> */}
        </div>
        <CompletionIndicator percent={planned_duration_percent} />
        {planned_duration_percent < 100 ? <span style={{ color: 'red' }}>Restant {formatTaskDuration(Math.abs(planned_duration - duration_hours))}</span> : null}
      </Flex>
      :
      null

      // <Flex align="center" gap="small">
      //   <div>Planifié : <b style={{ color: '#1677FF' }}>{formatCurrency(planned_cost)}</b></div>
      //   <CompletionIndicator percent={planned_cost_percent} />
      //   {planned_cost_percent < 100
      //     ? <span style={{ color: 'red' }}>Restant {formatCurrency(planned_cost - cost)}</span>
      //     : <span style={{ color: 'red' }}>+ {formatCurrency(planned_cost - cost)}</span>}

      // </Flex>
    }

    {ownSlots.length ? <div style={{ maxHeight: 210, overflow: 'auto' }}>
      <b><u>Crénaux :</u></b>
      {ownSlots.map(s => {
        const color = slotStatusesIndex[s.status]?.color || 'default'
        const isSameDay = s.start.toDateString() === s.end.toDateString()
        return <div key={s.id}>
          <div type="text"

            onClick={() => onSelectDate(s.start)} style={{ cursor: 'pointer' }}>
            <Space align="top" style={{ paddingBottom: 5, textDecoration: s.deleted ? 'line-through' : 'none' }}>
              <Badge color={color} />

              <Flex gap="small">
                {s.type === 'task'
                  ? (isSameDay ? formatDate(s.start) : <>{formatDate(s.start)} → {formatDate(s.end)}</>)
                  : (isSameDay ? <>{formatDate(s.start)} {formatTime(s.start)} → {formatTime(s.end)}</> : <>{formatDateTime(s.start)} → {formatDateTime(s.end)}</>)
                } <em>({formatTaskDuration(s.duration)})</em>
                &mdash;
                <b><ResourceLabel id={s.resource_id} clickable={false} /></b>
              </Flex >

            </Space>
          </div>
        </div>
      })}
    </div> : null}
    <div style={{ marginTop: 10 }}>
      <TaskMessagesView taskId={task.id} />
    </div>


    <Divider />
    <Flex justify="end" gap="small">
      {addSearchFilter && <Button icon={<Icon path={mdiMagnifyPlusOutline} size={0.6} />} size="small"
        onClick={() => addSearchFilter("projects", task.project_id)}
        title="Filtre sur cette affaire" shape="circle" type="dashed" />}
      <div style={{ flex: 1 }} />
      {onClickNewSlot && <Button size="small" type="default" onClick={() => onClickNewSlot({ task })}>Nouveau créneau</Button>}
      {can('task.show') && can('task.view') && <Button size="small" type="primary" onClick={() => navigate(`/tasks/${task.id}`)}>Voir la tâche</Button>}
    </Flex>

  </div>
}

export default function SlotTaskItemRenderer({
  name, task,
  metrics,
  // remainingIndicator,

  onClick, onChange, onSelectDate, onClickNewSlot, addSearchFilter }) {


  const [slots, loadSlots] = useSlots();

  const ownSlots = useMemo(() => (slots || []).filter(s => s.task_id === task?.id), [slots, task?.id]);

  useEffect(() => {
    if (task) {
      // loadSlots({ task_id: task.id });
    }
  }, [task, loadSlots]);



  let unit = task?.unit
  // if (task?.unit === 'h') {
  //   unit = 'h';
  // }

  // const duration = task?.duration;
  const { cost, duration: duration_hours, planned_cost, planned_duration,
    slots_count, slots_planned_count, slots_confirmed_count, slots_complete_count } = task.metrics || {};

  const showDurationIndicator = (unit === 'd' || unit === 'h') && duration_hours !== null
  const showAmountIndicator = unit === 'u' && cost > 0

  const showTaskStateIndicator = !showDurationIndicator && !showAmountIndicator
  const percentSlotsComplete = slots_count > 0 ? (100 * (slots_complete_count + slots_confirmed_count) / slots_count) : 0;
  const prestation = task?.prestation || {};

  // const isPlannable = task.previous_tasks_status_min === 'planned'
  const isTerminated = task.status === 'complete' || task.status === 'canceled'
  const isPlanned = task.status === 'planned'

  return (task &&
    <div className="slot-cell">
      <Popover placement="right" title={<div style={{ maxWidth: 400 }}>{name}</div>}
        content={() => <PopoverContent
          prestation={prestation}
          task={task} ownSlots={ownSlots}
          onSelectDate={onSelectDate}
          onChange={onChange}
          onClickNewSlot={onClickNewSlot}
          addSearchFilter={addSearchFilter} />}
      >
        <Flex gap="small" align="center" wrap="nowrap"
          onClick={() => onClick && onClick()}
          style={{
            width: '100%',
            cursor: 'pointer',
            opacity: isTerminated ? 0.5 : 1,
            color: isTerminated ? '#52c41a' : 'inherit'
          }}>
          <Space>
            <TaskStatusBadge value={task.status} dot deleted={task.deleted} />
            <PrestationIcon type={prestation.type} size={0.7} />
            {task?.type === 'after-sales' && <Tag color={"gold"} style={{ marginRight: 0 }}>SAV</Tag>}
          </Space>
          <div className="slot-text-truncated">
            {name}
          </div>
          {(() => {
            switch (true) {
              case isPlanned:
                {
                  let color = taskStatusesIdx['planned'].color
                  let icon = mdiCheckCircleOutline
                  // if ((showDurationIndicator && metrics?.planned_duration_percent > 110)
                  //   || (showAmountIndicator && metrics?.planned_cost_percent > 110)) {
                  //   color = "#faad14"
                  //   icon = mdiAlertCircleOutline
                  // }
                  // if ((showDurationIndicator && metrics?.planned_duration_percent > 125)
                  //   || (showAmountIndicator && metrics?.planned_cost_percent > 125)) {
                  //   color = "#f5222d"
                  //   icon = mdiAlertCircle
                  // }
                  return <div><Icon path={icon} size={0.8} color={color} /></div>
                }
              case isTerminated:
                return <div><Icon path={mdiCheckCircle} size={0.8} color="#52c41a" /></div>
              default:
                return <div>
                  {showDurationIndicator && unit === 'h' && duration_hours > 0
                    ? <CompletionLabel remaining={planned_duration - duration_hours}
                      unit={"h"}
                      percent={metrics?.planned_duration_percent} />
                    : null}
                  {showDurationIndicator && unit === 'd' && duration_hours > 0
                    ? <CompletionLabel remaining={durationInDaysFromHours(planned_duration - duration_hours)}
                      unit={"d"}
                      percent={metrics?.planned_duration_percent} />
                    : null}
                  {showAmountIndicator
                    ? <CompletionLabel remaining={(planned_cost - cost)} unit="€"
                      percent={metrics?.planned_cost_percent} />
                    : null}
                  {showTaskStateIndicator && <CompletionLabel
                    // remaining={slot_count - slot_planned_count}
                    unit={""}
                    percent={percentSlotsComplete} />}
                </div>
            }
          })()}
          {/* 
          {isTerminated ?
            <div>
              <Icon path={mdiCheckCircle} size={0.8} color="#52c41a" />
            </div>
            : <div>
              {showDurationIndicator && duration_hours > 0
                ? <CompletionLabel remaining={planned_duration - duration_hours}
                  unit={"h"}
                  percent={metrics?.planned_duration_percent} />
                : null}
              {showAmountIndicator
                ? <CompletionLabel remaining={(planned_cost - cost)} unit="€"
                  percent={metrics?.planned_cost_percent} />
                : null}
              {showTaskStateIndicator && <CompletionLabel
                // remaining={slot_count - slot_planned_count}
                unit={""}
                percent={percentSlotsComplete} />}
            </div>} */}
        </Flex>
      </Popover>
    </div >
  );
}