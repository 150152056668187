import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/metrics');

const { invalidateCache, withCache } = cache();


function mapToItemModel(input) {
  return { ...input };;
}



export async function listPoints({ name,
  from,
  to,
  res,
  sub,
  sub_prefix,
  dim } = {}) {

  const req = {
    name: name || '',
    from: from || '',
    to: to || '',
    res: res || '',
    sub: sub || '',
    sub_prefix: sub_prefix || '',
    dim: dim || ''
  }
  const points = await withCache(async () => {
    const { points } = await api.get(null, {}, req);
    return points || [];
  }, req)
  return points;
}

export async function refresh({ name,
  from,
  to,
  res,
  sub,
  sub_prefix,
  dim } = {}) {

  const req = {
    name: name || '',
    from: from || '',
    to: to || '',
    res: res || '',
    sub: sub || '',
    sub_prefix: sub_prefix || '',
    dim: dim || ''
  }
  await api.put(null, null, {}, req);
  invalidateCache(req)
  return true;

}




export function invalidateMetricsCache() {
  invalidateCache()
}





