import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Statistic } from "antd";
import useMetric from "../../hooks/metrics";


export default function InsightsKPI({ title, metric, time, res, sub, sub_prefix, dim, unit }) {

    const { value, loading, refreshMetric } = useMetric({ name: metric, from: time, to: time, res, sub, sub_prefix, dim });


    return (

        <div onClick={() => refreshMetric()} style={{ cursor: 'pointer' }}>
            <Statistic title={title} value={(isNaN(value) ? '-' : value)} precision={2} suffix={loading ? <Spin indicator={<LoadingOutlined spin />} /> : unit} />
        </div>
    );
}