
import { Flex } from "antd";
import { useParams } from "react-router-dom";
import InsightsKPI from "../../components/insights/InsightKPI";
import PlanningOverview from "../../components/planning/PlanningOverview";







export default function ResourcePlanning() {
  const { resourceId } = useParams();
  return <Flex vertical>
    <Flex style={{ padding: "1em" }} gap="large">
      <InsightsKPI title="Total planifié cette année" metric="total_planned_days" sub={resourceId} res="1fy" unit="jrs" />
      <InsightsKPI title="Total réalisé cette année" metric="realized_days" sub={resourceId} res="1fy" unit="jrs" />
    </Flex>
    <PlanningOverview resourceId={resourceId} />
  </Flex>

}