import { useCallback, useEffect, useState } from 'react';


import { createIssue, getIssue, listIssues, removeIssue, updateIssue } from '../services/api/support';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';

const INVALIDATION_KEY = Symbol('support');



export const supportStatuses = [
  // {
  //   label: 'À planifier',
  //   value: 'unplanned',
  //   color: 'default'
  // },
  // {
  //   label: "Planification",
  //   value: 'inplanning',
  //   color: '#1677ff'
  // },
  // {
  //   label: "Planifiée",
  //   value: 'planned',
  //   color: '#13C2C2'
  // },
  {
    label: "En cours",
    value: 'open',
    color: '#1677ff'
  },
  // {
  //   label: "Bloquée",
  //   value: 'blocked',
  //   color: 'orange'
  // },
  // {
  //   label: "En",
  //   value: 'canceled',
  //   color: 'red'
  // },
  {
    label: "Terminé",
    value: 'closed',
    color: '#52C41A'
  }
];

function getDefaults() {
  return {
    code: "",
    name: "",

  };
}

async function getItem(id, { withLogs = false } = {}) {
  if (id === '*' || !id) {
    return { item: getDefaults() };
  }
  try {
    const { item, changes } = await loaderWrap(getIssue(id, { withLogs }));
    return {
      item: {
        ...getDefaults(),
      },
      changes: changes
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function extractCode(code) {
  if (!code) return {};
  // AF0100018169CHA00
  return {
    support_type: code.slice(1, 4), // F01
    support_code: code.substr(4, 8), // 00018169
    support_invoicing_mode: code.substr(-5, 3),
  }
}


export function useSupportIssues({ all = false } = {}) {
  const [issues, setIssues] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listIssues()));
        const proj = await loaderWrap(listIssues({ all }));
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, all]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [issues, refresh, complete];
}



export function useSupportIssue(id, { withLogs = false } = {}) {
  const [{ issue, changes }, setIssue] = useState({});

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      const { item, changes } = await getItem(id, { withLogs });
      setIssue({ issue: item, changes });
    })();
  }, [id, counter]);

  const saveIssue = useCallback(async (item) => {
    if (!item) {
      throw new Error('No issue');
    }

    item = {
      ...item,
      expected_start_date: item.expected_start_date ? new Date(item.expected_start_date).toISOString() : null,
      expected_end_date: item.expected_end_date ? new Date(item.expected_end_date).toISOString() : null,
    }

    let out;
    if (item.id) {
      out = await loaderWrap(updateIssue(item));
    } else {
      out = await loaderWrap(createIssue(item));
    }
    setIssue(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteIssue = useCallback(async () => {
    await loaderWrap(removeIssue(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    issue: issue,
    changes: changes,
    reloadIssue: () => invalidate(id),
    isNewIssue: !issue?.id,
    setIssue,
    saveIssue,
    deleteIssue,
  };
}
