import { mdiAnimationOutline, mdiCalendar, mdiDrawing, mdiPackageVariant, mdiPackageVariantClosed, mdiSelectGroup, mdiTruckFastOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { ConfigProvider, Flex, Tag, Timeline } from "antd";
import { isValidElement, useMemo, useState } from "react";
import { formatCurrency, formatDate, formatDateTime } from "../../lib/format";
import AgencyLabel from "../agencies/AgencyLabel";
import DeliveryStatus from "../deliveries/DeliveryStatus";
import { ID } from "../id/id";
import Link from "../link/Link";
import OrderStatus from "../orders/OrderStatus";
import PickingStatus from "../picking/PickingStatus";
import PrestationLabel from "../prestations/PrestationLabel";
import ProjectLabel from "../projects/ProjectLabel";
import ProjectStatusLabel from "../projects/ProjectStatusLabel";
import ResourceLabel from "../resources/ResourceLabel";
import ShipmentStatus from "../shipments/ShipmentStatus";
import SlotStatusLabel from "../slots/SlotStatusLabel";
import TaskDurationUnitLabel from "../tasks/TaskDurationLabel";
import TaskLabel from "../tasks/TaskLabel";
import TaskStatusLabel from "../tasks/TaskStatusLabel";
import WarehouseLabel from "../warehouses/WarehouseLabel";

import Styles from './ChangeLog.module.scss';

function asColor(type) {
  switch (type) {
    case "create":
      return "green";
    case "update":
      return "blue";
    case "delete":
      return "red";
    default:
      return "grey";
  }
}

function asTypeLabel(type) {
  switch (type) {
    case "+":
      return <span style={{ color: "green" }}>+</span>;
    case "-":
      return <span style={{ color: "red" }}>-</span>;
    case "~":
    case "*":
      return <span style={{ color: "blue" }}>~</span>;
    default:
      return "";
  }
}

function asDataTypeProps(data_type) {
  switch (data_type) {
    case "prj":
      return { label: "Affaire", icon: mdiSelectGroup, color: "blue" }
    case "task":
      return { label: "Tâche", icon: mdiDrawing, color: "blue" }
    case "slot":
      return { label: "Créneau", icon: mdiCalendar, color: "blue" }
    case "shipment":
      return { label: "Expédition", icon: mdiPackageVariantClosed, color: "blue" }
    case "order":
      return { label: "Commande", icon: mdiAnimationOutline, color: "blue" }
    case "picking":
      return { label: "Préparation", icon: mdiPackageVariant, color: "blue" }
    case "delivery":
      return { label: "Livraison", icon: mdiTruckFastOutline, color: "blue" }
    default:
      return { label: data_type, icon: null, color: "grey" }
  }
}

function asFieldLabelValue({ data_type, field, value }) {

  switch (data_type + "." + field) {
    // Agency
    case "agency.name":
      return { label: "Agence", value }

    // Project
    // case "prj.id":
    //   return {
    //     label: "ID",
    //     value: <Link to={`/projects/${value}`}>{value}</Link>,
    //     raw: true
    //   }
    case "prj.name":
      return { label: "Nom", value }
    case "prj.description":
      return { label: "Description", value }
    case "prj.agency_id":
      return { label: "Agence", value: <AgencyLabel id={value} clickable /> }
    case "prj.customer_code":
      return { label: "Code client", value }
    case "prj.customer_name":
      return { label: "Nom client", value }
    case "prj.commentcial_code":
      return { label: "Code commercial", value }
    case "prj.phone":
      return { label: "Téléphone", value }
    case "prj.address":
      return { label: "Adresse", value }
    case "prj.expected_start_date":
      return { label: "Date de début souhaitée", value: formatDate(value) }
    case "prj.expected_end_date":
      return { label: "Date de fin souhaitée", value: formatDate(value) }
    case "prj.margin_rate":
      return { label: "Marge", value: value + "%" }
    case "prj.code":
      return { label: "Code", value }
    case "prj.status":
      return { label: "Statut", value: <ProjectStatusLabel value={value} /> }


    // Resource
    case "resource.name":
      return { label: "Ressource", value }

    // Task
    // case "task.id":
    //   return {
    //     label: "ID",
    //     value: <Link to={`/tasks/${value}`}>{value}</Link>,
    //     raw: true
    //   }
    case "task.name":
      return { label: "Tâche", value }
    case "task.description":
      return { label: "Description", value }
    case "task.project_id":
      return { label: "Affaire", value: <ProjectLabel id={value} clickable /> }
    case "task.prestation_id":
      return { label: "Prestation", value: <PrestationLabel id={value} clickable /> }
    case "task.parent_task_id":
      return { label: "Tâche parente", value }
    case "task.expected_level":
      return { label: "Niveau de compétence", value }
    case "task.type":
      return { label: "Type", value }
    case "task.priority":
      return { label: "Priorité", value }
    case "task.unit":

      return { label: "Unité de durée", value: <TaskDurationUnitLabel value={value} /> }
    // case "task.cost_planned":
    //   return { label: "Coût planifié", value: formatCurrency(value) }
    // case "task.planned_percent":
    //   return { label: "Pourcentage planifié", value: Math.round(value) + "%" }
    // case "task.complete_percent":
    //   return { label: "Pourcentage terminé", value: Math.round(value) + "%" }
    case "task.duration":
      return { label: "Durée", value }
    // case "task.duration_planned":
    //   return { label: "Durée planifiée", value }
    // case "task.duration_complete":
    //   return { label: "Durée terminée", value }
    case "task.status":
      return { label: "Statut", value: <TaskStatusLabel value={value} /> }
    case "task.order_number":
      return { label: "Numéro de commande", value }
    case "task.order_item_number":
      return { label: "Numéro de ligne", value }
    case "task.deleted":
      return { label: "Supprimé", value: value ? "Oui" : "Non" }

    case "task.salesman_code":
      return { label: "Code commercial", value }
    case "task.order_id":
      return { label: "Commande", value: <Link to={`/orders/${value}`}><ID value={value} /></Link> }
    case "task.cost":
      return { label: "Coût", value: formatCurrency(value) }
    case "task.min_date":
      return { label: "Date minimale", value: formatDate(value) }
    case "task.max_date":
      return { label: "Date maximale", value: formatDate(value) }
    case "task.agency_id":
      return { label: "Agence", value: <AgencyLabel id={value} clickable /> }
    case "task.is_order_valid":
      return { label: "Commande validée", value: value ? "Oui" : "Non" }
    case "task.is_invoiced":
      return { label: "Commande facturée", value: value ? "Oui" : "Non" }


    // Slot
    case "slot.id":
      return {}
    case "slot.start":
      return { label: "Début", value: formatDateTime(new Date(value)) }
    case "slot.end":
      return { label: "Fin", value: formatDateTime(new Date(value)) }
    case "slot.status":
      return { label: "Statut", value: <SlotStatusLabel status={value} /> }
    case "slot.duration":
      return { label: "Durée", value }
    case "slot.cancel_reason":
      return value ? { label: "Raison d'annulation", value } : {}
    case "slot.task_id":
      return { label: "Tâche", value: <TaskLabel id={value} clickable /> }
    case "slot.agency_id":
      return { label: "Agence", value: <AgencyLabel id={value} clickable /> }
    case "slot.project_id":
      return { label: "Affaire", value: <ProjectLabel id={value} clickable /> }
    case "slot.type":
      return { label: "Type", value }
    case "slot.real_duration":
      return { label: "Durée réelle", value }
    case "slot.resource_id":
      return { label: "Ressource", value: <ResourceLabel id={value} clickable /> }


    // Prestation
    case "prestation.name":
      return { label: "Prestation", value }
    case "resource.capacity":
      return { label: "Capacité", value }
    case "resource.type":
      return { label: "Type", value }
    case "resource.level":
      return { label: "Niveau", value }

    // Order
    // case "order.id":
    //   // return { label: "ID", value }
    case "order.order_number":
      return { label: "Numéro de commande", value }
    case "order.date":
      return { label: "Date", value: formatDate(value) }
    case "order.salesman_code":
      return { label: "Code commercial", value }
    case "order.shipping_mode":
      return { label: "Mode de livraison", value }
    case "order.status":
      return { label: "Statut", value: <OrderStatus value={value} /> }
    case "order.third_party_name":
      return { label: "Nom du tiers", value }

    case "order.shipping_date":
      return { label: "Date de livraison", value: formatDate(value) }

    // Shipment
    // case "shipment.id":
    //   // return { label: "ID", value }
    case "shipment.auditor_id":
      return { label: "Vérificateur", value: value ? <ResourceLabel id={value} clickable /> : 'Aucun' }
    case "shipment.need_to_be_verified":
      return { label: "Vérification nécessaire", value: value ? "Oui" : "Non" }
    case "shipment.order_id":
      return { label: "Commande", value: <Link to={`/orders/${value}`}><ID value={value} /></Link> }
    case "shipment.status":
      return { label: "Statut", value: <ShipmentStatus value={value} /> }
    case "shipment.task_id":
      return { label: "Tâche", value: <TaskLabel id={value} clickable /> }
    case "shipment.warehouse_id":
      return { label: "Entrepôt", value: <WarehouseLabel id={value} clickable /> }

    // Pickings
    case "picking.order_id":
      return { label: "Commande", value: <Link to={`/orders/${value}`}><ID value={value} /></Link> }
    case "picking.status":
      return { label: "Statut", value: <PickingStatus value={value} /> }
    case "picking.warehouse_id":
      return { label: "Entrepôt", value: <WarehouseLabel id={value} clickable /> }
    case "picking.shipment_id":
      return { label: "Expédition", value: <Link to={`/shipments/${value}`}><ID value={value} /></Link> }
    case "picking.resource_id":
      return { label: "Ressource", value: <ResourceLabel id={value} clickable /> }
    case "picking.started_at":
      return { label: "Début", value: formatDateTime(new Date(value)) }
    case "picking.ended_at":
      return { label: "Fin", value: formatDateTime(new Date(value)) }


    // Deliveries

    case "delivery.order_id":
      return { label: "Commande", value: <Link to={`/orders/${value}`}><ID value={value} /></Link> }
    case "delivery.status":
      return { label: "Statut", value: <DeliveryStatus value={value} /> }
    case "delivery.shipment_id":
      return { label: "Expédition", value: <Link to={`/shipments/${value}`}><ID value={value} /></Link> }
    case "delivery.warehouse_id":
      return { label: "Entrepôt", value: <WarehouseLabel id={value} clickable /> }
    case "delivery.delivery_date":
      return { label: "Date", value: formatDateTime(new Date(value)) }
    case "delivery.delivery_date_end":
      return { label: "Livré", value: formatDateTime(new Date(value)) }
    case "delivery.prestation_id":
      return { label: "Prestation", value: <PrestationLabel id={value} clickable /> }
    case "delivery.resource_id":
      return { label: "Ressource", value: <ResourceLabel id={value} clickable /> }
    case "delivery.task_id":
      return { label: "Tâche", value: <TaskLabel id={value} clickable /> }
    case "delivery.slot_id":
      return { label: "Créneau", value: <Link to={`/slots/${value}`}><ID value={value} /></Link> }
    case "delivery.tracking_number":
      return { label: "Numéro de suivi", value }

    default:
      return { label: field, value, raw: true }
  }
}

function ChangeField({ target, data_type, field, type, value, showRaw = false }) {
  const { label, value: val, raw } = asFieldLabelValue({ data_type, field, value })
  if (typeof val === "object" && !isValidElement(val)) {
    return null // Ignore
  }
  if (raw && !showRaw) {
    return null // Ignore
  }
  return label ? <div className={Styles.ChangeField} style={{ opacity: raw ? 0.5 : 1 }}>
    {asTypeLabel(type)} {label}: <b>{val}</b>
  </div > : null

}

function ChangesDetails({ changes, target, data_type, showRaw }) {
  const sortedChanges = useMemo(() => {
    return Object.entries(changes || {})
      .sort(([a], [b]) => a.localeCompare(b))
      .reduce((acc, [k, v]) => {
        acc[k] = v
        return acc
      }, {})
  }
    , [changes])


  return (
    <div style={{ paddingLeft: 10 }} >
      {Object.entries(sortedChanges).map(([k, { type, value }]) =>
        <ChangeField key={k} target={target} data_type={data_type} field={k} type={type} value={value} showRaw={showRaw} />
      )}
    </div>
  )
}


function ChangeLogItem({ item }) {
  const { label, action, changes, source, data_type, time, by_label } = item
  const { icon, label: dataTypeLabel } = asDataTypeProps(data_type)
  const [showRaw, setShowRaw] = useState(false)

  return (
    <div >
      {/* <Flex gap="small" style={{ marginBottom: 10 }}>
        <div style={{ fontSize: '0.9em', fontWeight: 500 }}>{formatDateTime(new Date(time))}</div>
        <div style={{ opacity: 0.5, fontWeight: 300, fontStyle: 'italic' }}>&mdash; {by_label}</div>
      </Flex> */}
      <Flex align="center" >
        {/* <Icon path={icon} size={0.8} color={asColor(action)} /> */}
        <Tag style={{ fontWeight: 600 }}>{dataTypeLabel}</Tag>
        <div style={{ fontWeight: 700 }} onDoubleClick={() => setShowRaw(!showRaw)}>{label}</div>
        {showRaw ? <div style={{ opacity: 0.5, marginLeft: 10, fontSize: '0.8em' }}>(raw)</div> : null}
      </Flex>
      <ChangesDetails changes={changes} target={source} data_type={data_type} showRaw={showRaw} />

    </div>
  )
}


function Dot({ icon, color }) {

  return <div className={Styles.icon}>
    <Icon path={icon} size={0.8} color={color} />
  </div>
}


export default function ChangeLog({ changes }) {
  const items = useMemo(() => {
    return (changes || [])
      .sort((a, b) => b.time > a.time ? 1 : -1)
      .map((item) => {
        const { id, action, time, data_type, by } = item
        const { icon, label: dataTypeLabel } = asDataTypeProps(data_type)
        let { by_label } = item
        if (by === "sync-agent") {
          by_label = "Cegid"
        }
        return {
          key: id,
          position: 'left',
          label: <div>
            <div style={{ fontSize: '0.9em', fontWeight: 500 }}>{formatDateTime(new Date(time))}</div>
            <div style={{ opacity: 0.5, fontWeight: 300, fontStyle: 'italic' }}>&mdash; {by_label}</div>
          </div>,
          dot: <Dot icon={icon} color={asColor(action)} />,
          children: <ChangeLogItem item={item} />,
          // color: 'transparent', // asColor(action),
        }
      })

  }, [changes])

  return (
    <div>
      {/* <Flex>
        <Segmented items={[
          { label: "Tout", value: "all" },
          { label: "Créations", value: "create" },
          { label: "Mises à jour", value: "update" },
          { label: "Suppressions", value: "delete" },
        ]} />
      </Flex> */}
      <ConfigProvider
        theme={{
          components: {
            Timeline: {
              dotBg: 'transparent',

            }
          }
        }}
      >
        <Timeline
          style={{ maxWidth: 600 }}
          mode="left"
          items={items}
        />
      </ConfigProvider>
    </div>
  );
}