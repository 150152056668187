import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/support');
const { invalidateCache, withCache } = cache();

function mapFromIssueModel(input) {
  return { ...input };;
}

function mapToIssueModel(input) {
  return { ...input };
}

export async function listIssues({ all = false } = {}) {
  const items = await withCache(async () => {
    const { items } = await api.get("", {}, {
      all: all ? "true" : "false"
    });
    return items;
  }, { all });
  return items ? items.map(mapToIssueModel) : [];
}

export async function getIssue(id, { withLogs = false } = {}) {
  const { item, changes } = await withCache(async () => {
    const res = await api.get(id, {}, { withLogs });
    if (!res.item) {
      throw new Error('Support not found');
    }
    return res;
  }, id + (withLogs ? '+logs' : ''))

  const support = mapToIssueModel(item);
  return { item: support, changes };
}

export async function askSupport({ context, messages }) {
  const { reply } = await api.post('ask', { context, messages });
  return reply;
}

export async function createIssue(support) {
  const { item } = await api.post('', { item: mapFromIssueModel(support) });
  if (item) {
    invalidateCache(item.id)
    return mapToIssueModel(item)
  }
  return undefined;
}

export async function updateIssue(support) {
  const res = await api.put('', { item: mapFromIssueModel(support) });
  invalidateCache(support.id)
  return res ? support : undefined;
}

export async function removeIssue(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}

export function invalidateSupportCache(issueId) {
  invalidateCache(issueId)
}

export async function listSupportComments(issueId) {
  const { items } = await api.get(`${issueId}/comments`);
  return items;
}

export async function addSupportComment(issueId, comment) {
  const { success } = await api.post(`${issueId}/comments`, { content: comment });
  return success;
}