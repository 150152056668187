import { Progress, Result } from "antd";
import { useAgencies } from "../hooks/agencies";
import { usePrestations } from "../hooks/prestations";
import { useProjectsLoader } from "../hooks/projects";
import { useResourcePrestations } from "../hooks/resource-prestations";
import { useResources } from "../hooks/resources";
import { useTasks } from "../hooks/tasks";
import { useWarehouses } from "../hooks/warehouses";


export default function AppMainLoader({ children }) {



    const [, , tasksComplete] = useTasks()
    const [, , resourcesComplete] = useResources()
    const { complete: projectsComplete } = useProjectsLoader()
    const [, , prestationsComplete] = usePrestations()
    const [, , resourcePrestationsComplete] = useResourcePrestations()
    const [, , agenciesComplete] = useAgencies()
    const [, , whComplete] = useWarehouses()

    const steps = [tasksComplete, resourcesComplete, projectsComplete, prestationsComplete, resourcePrestationsComplete, agenciesComplete, whComplete]

    const total = steps.length
    const completed = steps.filter(Boolean).length

    const allComplete = total === completed
    if (!allComplete) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Result icon={<Progress type="circle" percent={Math.ceil(100 * completed / total)} />}
                title="Chargement en cours..."
                subTitle="Chronos est en train de précharger les données de l'application."
            />
        </div>
    }

    return children

}