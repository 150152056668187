import { useCallback, useEffect, useMemo, useState } from "react";
import { listPoints, refresh } from "../services/api/metrics";


export default function useMetric({ name,
    from,
    to,
    res,
    sub,
    sub_prefix,
    dim }) {
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadPoints = useCallback(async () => {
        setLoading(true);
        try {
            const points = await listPoints({ name, from, to, res, sub, sub_prefix, dim });
            setPoints(points);
        } catch (error) {
            console.error('Erreur lors du chargement des points:', error);
        } finally {
            setLoading(false);
        }
    }, [name, from, to, res, sub, sub_prefix, dim]);


    const refreshMetric = useCallback(() => {
        setLoading(true);
        refresh({ name, from, to, res, sub, sub_prefix, dim })
            .then(() => {
                loadPoints();
            })
    }, [name, from, to, res, sub, sub_prefix, dim, loadPoints]);

    useEffect(() => {
        loadPoints();
    }, [loadPoints]);


    const latest = useMemo(() => points.length ? points[points.length - 1] : null, [points]);

    return {
        points,
        value: latest?.v,
        loading, loadPoints,
        refreshMetric,
    };
}