import React, { useRef, useState } from 'react';



import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAgency } from './hooks/agencies';
import { usePrestation } from './hooks/prestations';
import { useProject } from './hooks/projects';
import { useResource } from './hooks/resources';
import { useTask } from './hooks/tasks';
import { useWarehouse } from './hooks/warehouses';
import Agencies from './pages/agencies/Agencies';
import AgenciesList from './pages/agencies/AgenciesList';
import AgencyForm from './pages/agencies/AgencyForm';
import Items from './pages/items/Items';
import ItemsList from './pages/items/ItemsList';
import PickingPools from './pages/pickingpools/PickingPools';
import Planning from './pages/planning/Planning';
import PlanningView from './pages/planning/PlanningView';
import PrestationForm from './pages/prestations/PrestationForm';
import Prestations from './pages/prestations/Prestations';
import PrestationsList from './pages/prestations/PrestationsList';
import ProjectForm from './pages/projects/ProjectForm';
import Projects from './pages/projects/Projects';
import ProjectsList from './pages/projects/ProjectsList';
import ResourceForm from './pages/resources/ResourceForm';
import ResourceItem from './pages/resources/ResourceItem';
import ResourcePrestations from './pages/resources/ResourcePrestations';
import Resources from './pages/resources/Resources';
import ResourcesList from './pages/resources/ResourcesList';
import TaskDocuments from './pages/tasks/TaskDocuments';
import TaskForm from './pages/tasks/TaskForm';
import TaskItem from './pages/tasks/TaskItem';
import TaskPlanning from './pages/tasks/TaskPlanning';
import TaskResources from './pages/tasks/TaskResources';
import Tasks from './pages/tasks/Tasks';
import TasksList from './pages/tasks/TasksList';
import WarehouseForm from './pages/warehouses/WarehouseForm';
import Warehouses from './pages/warehouses/Warehouses';
import WarehousesList from './pages/warehouses/WarehousesList';

import { useSelectedPickingPool } from './hooks/pickingpools';
import PickingPoolForm from './pages/pickingpools/PickingPoolForm';
import PickingPoolsList from './pages/pickingpools/PickingPoolsList';
// import PickingPoolOrders from './pages/pickingpools/_PickingPoolOrders';
// import Imports from './pages/imports/Imports';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import OrderPlanning from './components/orders/OrderPlanning';
import { useOrder } from './hooks/orders';
import { useSupportIssue } from './hooks/support';
import Event from './pages/event/Event';
import Insights from './pages/insights/Insights';
import OrderDeliveriesView from './pages/orders/OrderDeliveriesView';
import OrderEvents from './pages/orders/OrderEvents';
import OrderItem from './pages/orders/OrderItem';
import OrderPickingsView from './pages/orders/OrderPickingsView';
import Orders from './pages/orders/Orders';
import OrderShipmentsView from './pages/orders/OrderShipmentsView';
import OrdersList from './pages/orders/OrdersList';
import OrderTasksView from './pages/orders/OrderTasksView';
import OrderView from './pages/orders/OrderView';
import PrestationItem from './pages/prestations/PrestationItem';
import PrestationResources from './pages/prestations/PrestationResources';
import ProjectDocuments from './pages/projects/ProjectDocuments';
import ProjectItem from './pages/projects/ProjectItem';
import ProjectPlanning from './pages/projects/ProjectPlanning';
import ProjectTasks from './pages/projects/ProjectTasks';
import ResourceNotifications from './pages/resources/ResourceNotifications';
import ResourcePlanning from './pages/resources/ResourcePlanning';
import { Settings } from './pages/settings/Settings';
import ShipmentDeliveriesList from './pages/shipments/ShipmentDeliveriesList';
import Shipments from './pages/shipments/Shipments';
import ShipmentsList from './pages/shipments/ShipmentsList';
import ShipmentsPicking from './pages/shipments/ShipmentsPicking';
import TaskEvents from './pages/tasks/TasksEvents';

function Crumb({ label, isNew, newLabel }) {
  return <b>{isNew ? (newLabel || 'Nouveau') : label || ''}</b>;
}

function CrumbAgency({ match }) {
  const { agency, isNewAgency } = useAgency(match.params.agencyId);
  return <Crumb label={agency?.name} isNew={isNewAgency} />;
}

function CrumbProject({ match }) {
  const { project, isNewProject } = useProject(match.params.projectId);
  return <Crumb label={project?.name} isNew={isNewProject} />;
}

function CrumbPrestation({ match }) {
  const { prestation, isNewPrestation } = usePrestation(match.params.prestationId);
  return <Crumb label={prestation?.name} isNew={isNewPrestation} />;
}

function CrumbResource({ match }) {
  const { resource, isNewResource } = useResource(match.params.resourceId);
  return <Crumb label={resource?.name} isNew={isNewResource} />;
}

function CrumbTask({ match }) {
  const { task, isNewTask } = useTask(match.params.taskId);
  const [p] = useSearchParams()

  return <Crumb label={task?.name} isNew={isNewTask} newLabel={p.get("type") === 'after-sales' ? 'Nouveau SAV' : null} />;
}

function CrumbWarehouse({ match }) {
  const { warehouse, isNewWarehouse } = useWarehouse(match.params.warehouseId);
  return <Crumb label={warehouse?.name} isNew={isNewWarehouse} />;
}

function CrumbPickingPool({ match }) {

  // const { pickingPool, isNewPickingPool } = usePickingPool(match.params.pickingPoolId);
  const [pickingPool] = useSelectedPickingPool()
  return <Crumb label={pickingPool?.name} />;
}

function CrumbSupport({ match }) {
  const { issue, isNewIssue } = useSupportIssue(match.params.warehouseId);
  return <Crumb label={issue?.name} isNew={isNewIssue} />;
}

function CrumbOrder({ match }) {
  const { order } = useOrder(match.params.orderId);
  const navigate = useNavigate();
  const ref = useRef(null)
  const [edit, setEdit] = useState(false)
  const onEnter = () => {
    navigate(`/orders/order-${ref.current.input.value}`)
    setEdit(false);
  }

  const orderNumber = order?.order_number || match?.params?.orderId?.replace('order-', '')

  return <Crumb label={<Space>
    <div>N°</div>
    {edit ?
      <Space.Compact>
        <Input ref={ref}
          maxLength={5}
          onBlur={() => setTimeout(() => setEdit(false), 100)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnter();
            }
          }}
          style={{ maxWidth: '5em' }}
          defaultValue={orderNumber}
        />
        <Button type="primary" icon={<ArrowRightOutlined />} onClick={onEnter} title="Ouvrir la commande" />
      </Space.Compact>
      : <div style={{ cursor: 'pointer' }} onClick={() => setEdit(true)}>{orderNumber}</div>}
  </Space>} />;
}



export const routes = [
  {
    path: '/',
    key: 'home',
    // breadcrumb: ">",
    // component: <Overview />,
    component: <Navigate to="/planning/view" replace />
  },
  {
    path: "/planning",
    key: "planning",
    component: <Planning />,
    breadcrumb: "Planning",
    children: [
      {
        path: "",
        key: "planningRoot",
        breadcrumb: "Planning",
        component: <Navigate to="view" replace />
      },
      {
        path: "view",
        key: "planningProjects",
        // breadcrumb: "Planning",
        component: <PlanningView />,
      },
      // {
      //   path: "projects",
      //   key: "planningProjects",
      //   breadcrumb: "Affaires",
      //   component: <PlanningProjects />,
      // },
      // {
      //   path: "resources",
      //   key: "planningResources",
      //   breadcrumb: "Resources",
      //   component: <PlanningResources />,
      // },
    ],

  },
  {
    path: "/agencies",
    key: "agencies",
    component: <Agencies />,
    children: [
      {
        path: "",
        key: "agenciesList",
        breadcrumb: "Agences",
        component: <AgenciesList />,
      },
      {
        path: ":agencyId",
        key: "agencyForm",
        breadcrumb: CrumbAgency,
        component: <AgencyForm />,
      },
    ]
  },

  {
    path: "/projects",
    key: "projects",
    component: <Projects />,
    children: [
      {
        path: "",
        key: "projectsList",
        breadcrumb: "Affaires",
        component: <ProjectsList />,
      },
      // {
      //   path: ":projectId",
      //   key: "projectForm",
      //   breadcrumb: CrumbProject,
      //   component: <ProjectForm />,
      // },
      {
        path: ":projectId",
        key: "projectItem",
        breadcrumb: CrumbProject,
        component: <ProjectItem />,
        children: [
          {
            path: "",
            key: "resourceRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "projectForm",
            breadcrumb: CrumbProject,
            component: <ProjectForm />,
          },
          {
            path: "tasks",
            key: "projectTasks",
            breadcrumb: CrumbProject,
            component: <ProjectTasks />,
          },
          {
            path: "documents",
            key: "projectDocuments",
            breadcrumb: CrumbProject,
            component: <ProjectDocuments />,
          },
          {
            path: "planning",
            key: "projectPlanning",
            breadcrumb: CrumbProject,
            component: <ProjectPlanning />,
          },
        ]
      },
    ]
  },

  {
    path: "/tasks",
    key: "tasks",
    component: <Tasks />,
    children: [
      {
        path: "",
        key: "tasksList",
        breadcrumb: "Tâches",
        component: <TasksList />,
      },
      {
        path: ":taskId",
        key: "taskForm",
        breadcrumb: CrumbTask,
        component: <TaskItem />,
        children: [
          {
            path: "",
            key: "resourceRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "taskForm",
            breadcrumb: CrumbTask,
            component: <TaskForm />,
          },
          {
            path: "resources",
            key: "taskResources",
            breadcrumb: CrumbTask,
            component: <TaskResources />,
          },
          {
            path: "planning",
            key: "taskPlanning",
            breadcrumb: CrumbTask,
            component: <TaskPlanning />,
          },
          {
            path: "documents",
            key: "taskDocuments",
            breadcrumb: CrumbTask,
            component: <TaskDocuments />,
          },
          {
            path: "events",
            key: "taskEvents",
            breadcrumb: CrumbTask,
            component: <TaskEvents />,
          },
        ]
      },
    ]
  },

  {
    path: "/resources",
    key: "resources",
    component: <Resources />,
    children: [
      {
        path: "",
        key: "resourcesList",
        breadcrumb: "Ressources",
        component: <ResourcesList />,
      },
      {
        path: ":resourceId",
        key: "resourceItem",
        component: <ResourceItem />,
        breadcrumb: CrumbResource,
        children: [
          {
            path: "",
            key: "resourceRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "resourceForm",
            breadcrumb: CrumbResource,
            component: <ResourceForm />,
          },
          {
            path: "prestations",
            key: "resourcePrestation",
            breadcrumb: CrumbResource,
            component: <ResourcePrestations />,
          },
          {
            path: "planning",
            key: "resourcePlanning",
            breadcrumb: CrumbResource,
            component: <ResourcePlanning />,
          },
          {
            path: "notifications",
            key: "resourceNotifications",
            breadcrumb: CrumbResource,
            component: <ResourceNotifications />,
          },
        ]
      },
    ]
  },

  {
    path: "/prestations",
    key: "prestations",
    component: <Prestations />,
    children: [
      {
        path: "",
        key: "prestationsList",
        breadcrumb: "Prestations",
        component: <PrestationsList />,
      },

      {
        path: ":prestationId",
        key: "prestationItem",
        component: <PrestationItem />,
        breadcrumb: CrumbPrestation,
        children: [
          {
            path: "",
            key: "prestationRoot",
            // breadcrumb: "Planning",
            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "prestationForm",
            breadcrumb: CrumbPrestation,
            component: <PrestationForm />,
          },
          {
            path: "resources",
            key: "prestationPrestation",
            breadcrumb: CrumbPrestation,
            component: <PrestationResources />,
          },
        ]
      },

    ]
  },
  {
    path: "/items",
    key: "items",
    component: <Items />,
    children: [
      {
        path: "",
        key: "itemsList",
        breadcrumb: "Produits",
        component: <ItemsList />,
      },
    ]
  },

  {
    path: "/warehouses",
    key: "warehouses",
    component: <Warehouses />,
    children: [
      {
        path: "",
        key: "warehousesList",
        breadcrumb: "Dépôts",
        component: <WarehousesList />,
      },
      {
        path: ":warehouseId",
        key: "warehouseForm",
        breadcrumb: CrumbWarehouse,
        component: <WarehouseForm />,
      },
    ]
  },
  // {
  //   path: "/orders",
  //   keys: "orders",
  //   children: [

  //   ]
  // },

  {
    path: "pickingpools",
    key: "/pickingpools",
    component: <PickingPools />,
    children: [
      {
        path: "",
        key: "pickingpoolsList",
        breadcrumb: "Pools",
        component: <PickingPoolsList />,
      },
      {
        path: ":pickingPoolId",
        key: "pickingpoolForm",
        breadcrumb: CrumbPickingPool,
        component: <PickingPoolForm />,
      },
      // {
      //   path: ":pickingPoolId",
      //   key: "pickingpoolForm",
      //   breadcrumb: CrumbPickingPool,
      //   component: <PickingPoolItem />,
      //   children: [
      //     {
      //       path: "",
      //       key: "pickingpoolRoot",
      //       component: <Navigate to="orders" replace />
      //     },
      //     {
      //       path: "details",
      //       key: "pickingpoolForm",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolForm />,
      //     },
      //     {
      //       path: "orders",
      //       key: "pickingpoolOrders",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolOrders />,
      //     },
      //     {
      //       path: "picking",
      //       key: "pickingpoolPicking",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolPicking />,
      //     },
      //   ]
      // },

    ]
  },

  {
    path: "orders",
    key: "/orders",
    component: <Orders />,
    children: [
      // {
      //   path: "",
      //   key: "orderslists",
      //   breadcrumb: "Commandes",
      //   component: <OrdersList />,
      // },
      {
        path: "",
        key: "orderslists",
        breadcrumb: "Commandes",
        // breadcrumb: CrumbPickingPool,
        component: <OrdersList />,
      },

      {
        path: ":orderId",
        key: "orderItem",
        component: <OrderItem />,
        breadcrumb: CrumbOrder,
        children: [
          {
            path: "",
            key: "orderRoot",

            component: <Navigate to="details" replace />
          },
          {
            path: "details",
            key: "orderView",
            breadcrumb: CrumbOrder,
            component: <OrderView />,
          },
          {
            path: "tasks",
            key: "orderTasks",
            breadcrumb: CrumbOrder,
            component: <OrderTasksView />,
          },
          {
            path: "shipments",
            key: "orderShipments",
            breadcrumb: CrumbOrder,
            component: <OrderShipmentsView />,
          },
          {
            path: "pickings",
            key: "orderPickings",
            breadcrumb: CrumbOrder,
            component: <OrderPickingsView />,
          },
          {
            path: "deliveries",
            key: "orderDeliveries",
            breadcrumb: CrumbOrder,
            component: <OrderDeliveriesView />,
          },
          {
            path: "planning",
            key: "orderPlanning",
            breadcrumb: CrumbOrder,
            component: <OrderPlanning />,
          },
          {
            path: "events",
            key: "orderEvents",
            breadcrumb: CrumbOrder,
            component: <OrderEvents />,
          },
        ]
      },


      // {
      //   path: "picking",
      //   key: "pickingpoolPicking",
      //   breadcrumb: "Commandes",
      //   // breadcrumb: CrumbPickingPool,
      //   component: <PickingPoolPicking />,
      // },
      // {
      //   path: ":pickingPoolId",
      //   key: "pickingpoolForm",
      //   breadcrumb: CrumbPickingPool,
      //   component: <PickingPoolItem />,
      //   children: [
      //     {
      //       path: "",
      //       key: "pickingpoolRoot",
      //       component: <Navigate to="orders" replace />
      //     },
      //     {
      //       path: "details",
      //       key: "pickingpoolForm",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolForm />,
      //     },
      //     {
      //       path: "orders",
      //       key: "pickingpoolOrders",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolOrders />,
      //     },
      //     {
      //       path: "picking",
      //       key: "pickingpoolPicking",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolPicking />,
      //     },
      //   ]
      // },

    ]
  },

  {
    path: "/shipments",
    key: "shipments",
    breadcrumb: "Expéditions",
    component: <Shipments />,

    children: [
      {
        path: "",
        key: "shipmentsRoot",
        component: <Navigate to="list" replace />
      },
      {
        path: "topick",
        key: "shipmentsPicking",
        breadcrumb: CrumbPickingPool,
        component: <ShipmentsPicking />,
      },
      {
        path: "list",
        key: "shipmentsToSend",
        breadcrumb: CrumbPickingPool,
        component: <ShipmentsList />,
      },
      {
        path: "deliveries",
        key: "deliveries",
        breadcrumb: CrumbPickingPool,
        component: <ShipmentDeliveriesList />,
      },
      // {
      //   path: ":pickingPoolId",
      //   key: "shipmentsForm",
      //   breadcrumb: CrumbPickingPool,
      //   component: <PickingPoolItem />,
      //   children: [
      //     {
      //       path: "",
      //       key: "pickingpoolRoot",
      //       component: <Navigate to="orders" replace />
      //     },
      //     {
      //       path: "details",
      //       key: "pickingpoolForm",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolForm />,
      //     },
      //     {
      //       path: "orders",
      //       key: "pickingpoolOrders",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolOrders />,
      //     },
      //     {
      //       path: "picking",
      //       key: "pickingpoolPicking",
      //       breadcrumb: CrumbPickingPool,
      //       component: <PickingPoolPicking />,
      //     },
      //   ]
      // },

    ]
  },

  // {
  //   path: "/imports",
  //   key: "imports",
  //   breadcrumb: "Imports",
  // component: <Imports />,
  // },

  {
    path: "/settings",
    key: "settings",
    breadcrumb: "Paramètres",
    component: <Settings />
  },

  {
    path: "/insights",
    key: "insights",
    breadcrumb: "Tableau de bord",
    component: <Insights />
  },

  // {
  //   path: "/support",
  //   key: "support",
  //   component: <Support />,
  //   children: [
  //     {
  //       path: "",
  //       key: "supportList",
  //       breadcrumb: "Support",
  //       component: <SupportIssuesList />,
  //     },

  //     // {
  //     //   path: ":issueId",
  //     //   key: "issueItem",
  //     //   breadcrumb: CrumbSupport,
  //     //   component: <ProjectItem />,
  //     //   children: [
  //     //     {
  //     //       path: "",
  //     //       key: "resourceRoot",
  //     //       // breadcrumb: "Planning",
  //     //       component: <Navigate to="details" replace />
  //     //     },
  //     //     {
  //     //       path: "details",
  //     //       key: "projectForm",
  //     //       breadcrumb: CrumbProject,
  //     //       component: <ProjectForm />,
  //     //     },
  //     //     {
  //     //       path: "tasks",
  //     //       key: "projectTasks",
  //     //       breadcrumb: CrumbProject,
  //     //       component: <ProjectTasks />,
  //     //     },
  //     //     {
  //     //       path: "documents",
  //     //       key: "projectDocuments",
  //     //       breadcrumb: CrumbProject,
  //     //       component: <ProjectDocuments />,
  //     //     },
  //     //     {
  //     //       path: "planning",
  //     //       key: "projectPlanning",
  //     //       breadcrumb: CrumbProject,
  //     //       component: <ProjectPlanning />,
  //     //     },
  //     //   ]
  //     // },
  //   ]
  // },

  {
    path: "/event/:accessKey/:slotId/:token",
    key: "home",
    breadcrumb: "Accueil",
    component: <Event />,
    public: true,
  }
];
