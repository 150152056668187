import {
  mdiAccountHardHatOutline,
  mdiAnimationOutline,
  mdiCalendarMonthOutline, mdiCog,
  mdiDrawing, mdiNut,
  mdiPackageVariantClosed,
  mdiProgressWrench,
  mdiSelectGroup,
  mdiStoreOutline,
  mdiTrayFull,
  mdiWarehouse
} from '@mdi/js';
import Icon from '@mdi/react';
import { NavLink } from 'react-router-dom';

import { Divider, Flex, Tag } from 'antd';

import logov2 from '../../assets/logo.v2.svg';
import { useSelectedAgencyID } from '../../hooks/agencies';
import { useAuth } from '../../hooks/auth';
import { useSelectedPickingPool } from '../../hooks/pickingpools';
import './AppSidebar.scss';

export default function AppSidebar() {

  const [agencyId] = useSelectedAgencyID();
  const [pickingPool] = useSelectedPickingPool();
  const { can, email } = useAuth();

  const linkClass = (baseClasses = '') => ({ isActive }) => baseClasses + (isActive ? ' is-active' : '');
  return (
    <section className="AppSidebar app-sidebar">
      <Flex gap="small" className="logo"
        style={{ cursor: 'pointer' }}
        onClick={() => window.location.reload()}
        align="center">
        <img src={logov2} alt="logo" />
        {/* <Icon path={logov2} size={1.5} color="#ffffff" /> */}
        <h1>
          Chronos
          {/* <CalendarOutlined /> */}
        </h1>
      </Flex>
      {process.env.REACT_APP_ENV !== 'production' && (<p>
        <Tag color="#f50">{process.env.REACT_APP_ENV}</Tag>
      </p>)}
      <nav className="menu">
        {/* {can('insights.show') &&
          <NavLink to="/insights" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiChartArc} size={1} />
            </span>
            <span className="text">Statistiques</span>
          </NavLink>} */}
        {can('order.show') &&
          <NavLink to="/orders" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiAnimationOutline} size={1} />
            </span>
            <span className="text">Commandes</span>
          </NavLink>}
        {(can('picking.show') || can('shipment.show')) && pickingPool?.is_main ?
          <NavLink to="/shipments" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiPackageVariantClosed} size={1} />
            </span>
            <span className="text">Expéditions</span>
          </NavLink>
          : null}
        {can('planning.show') &&
          <NavLink to="/planning" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiCalendarMonthOutline} size={1} />
            </span>
            <span className="text">Planning</span>
          </NavLink>
        }
        {can('project.show') &&
          <NavLink to="/projects" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiSelectGroup} size={1} />
            </span>
            <span className="text">Affaires</span>
          </NavLink>
        }
        {can('task.show') &&
          <NavLink to="/tasks" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiDrawing} size={1} />
            </span>
            <span className="text">Tâches</span>
          </NavLink>
        }
      </nav>
      <Divider />
      <nav className='menu' >
        {can('agency.show') && !agencyId && <NavLink to="/agencies" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiStoreOutline} size={1} />
          </span>
          <span className="text">Agences</span>
        </NavLink>}
        {can('resource.show') &&
          <NavLink to="/resources" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiAccountHardHatOutline} size={1} />
            </span>
            <span className="text">Ressources</span>
          </NavLink>
        }
        {can('prestation.show') &&
          <NavLink to="/prestations" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiProgressWrench} size={1} />
            </span>
            <span className="text">Prestations</span>
          </NavLink>
        }
        {can('item.show') &&
          <NavLink to="/items" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiNut} size={1} />
            </span>
            <span className="text">Produits</span>
          </NavLink>
        }
        {can('warehouse.show') &&
          <NavLink to="/warehouses" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiWarehouse} size={1} />
            </span>
            <span className="text">Dépôts</span>
          </NavLink>
        }
        {can('pickingpool.show') &&
          <NavLink to="/pickingpools" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiTrayFull} size={1} />
            </span>
            <span className="text">Pools</span>
          </NavLink>
        }
        {can('settings.show') &&
          <NavLink to="/settings" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiCog} size={1} />
            </span>
            <span className="text">Paramètres</span>
          </NavLink>
        }
        {/* {can('support.show') &&
          <NavLink to="/support" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiChatQuestionOutline} size={1} />
            </span>
            <span className="text">Support</span>
          </NavLink>
        } */}
        {/* {can('import.create') &&
          <NavLink to="/imports" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiFileImportOutline} size={1} />
            </span>
            <span className="text">Imports</span>
          </NavLink>
        } */}
      </nav>
    </section >
  );
}
