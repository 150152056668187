




export default function Insights() {
    return (
        <div>
            <h1>Insights</h1>
        </div>
    );
}

