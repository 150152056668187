import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import SupportChatbot from "./SupportChatbot";


export default function SupportButton() {
    const [inc, setInc] = useState(0);

    const open = () => {
        setInc(+new Date());
    }

    return <>
        <Button shape="circle" icon={<QuestionCircleOutlined />} onClick={() => open()} />
        {inc > 0 && <SupportChatbot key={inc} onClose={() => setInc(0)} />}
    </>
}