import { Flex } from "antd";
import { useParams } from "react-router-dom";
import InsightsKPI from "../../components/insights/InsightKPI";
import PlanningOverview from "../../components/planning/PlanningOverview";




export default function ProjectPlanning() {
  const { projectId } = useParams();

  return <Flex vertical>
    <Flex style={{ padding: "1em" }} gap="large">
      <InsightsKPI title="Total planifié cette année (jrs)" metric="total_planned_days" sub={projectId} res="1fy" />
      <InsightsKPI title="Total réalisé cette année (jrs)" metric="realized_days" sub={projectId} res="1fy" />
    </Flex>
    <PlanningOverview projectId={projectId} />
  </Flex>

}